import { Controller } from "@hotwired/stimulus"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

// Connects to data-controller="map"
export default class extends Controller {
  static values = {
    apiKey: String,
    markers: Array
  }

  connect() {
    mapboxgl.accessToken = this.apiKeyValue
    const markers = [4.3989422, 50.839626]
    this.map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/mapbox/streets-v10",
      // style: "mapbox://styles/pdunleav/cjofefl7u3j3e2sp0ylex3cyb",
      center: markers,
      zoom: 7
    })
    this.addMarkerToMap()
  }
  addMarkerToMap() {
    (markers) => {
      new mapboxgl.Marker()
          .setLngLat([ marker.lng, marker.lat ])
          .addTo(this.map)
    };
  }
}

