import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        const agency_1 = document.getElementById('job_recruiters_amount_1');
        const agencies = document.querySelector('.hidden_job_recruiters_amount');
        const retainer_service = document.getElementById("job_service_type_retainer");
        const success_service = document.getElementById("job_service_type_success");
        const external_agencies = document.querySelector('.external_agencies');
        const exclusivity = document.getElementById('job_exclusivity_true');



        document.addEventListener('change', () => {
            if (exclusivity.checked)  {
                external_agencies.classList.remove('hidden')
                success_service.checked = true
                retainer_service.disabled = true
            }
            else {
                external_agencies.classList.add('hidden')
                // retainer_service.checked = true
                retainer_service.disabled = false
            }
            if (retainer_service.checked)  {
                agency_1.checked = true
                agencies.classList.add('hidden')
            }
            else {
                agencies.classList.remove('hidden')
            }
        })
    }
}