import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        console.log("Hello, Stimulus!", this.element)
        const management = document.getElementById('job_management_role_false')
        const team_size = document.getElementById('job_managed_team_size')
        const management_team = document.querySelector( ".managing_team")
        const hybrid = document.getElementById("job_working_type_hybrid")
        const remote_frequency = document.querySelector(".remote_frequencies")

        document.addEventListener('change', () => {
            if (management.checked) {
                team_size.value = "0"
                management_team.classList.add('hidden')
            } else if (!management.checked) {
                management_team.classList.remove('hidden')
            }

            if (hybrid.checked) {
                remote_frequency.classList.remove('hidden')
            }
        });
    }
}
