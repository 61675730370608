import { Controller } from "@hotwired/stimulus"
// Don't forget to import the NPM package
import Typed from "typed.js"

export default class extends Controller {
    connect() {
        new Typed(this.element, {
            strings: ["Hire your Modern Day Staffing Agency!", "Hire your perfect match..."],
            typeSpeed: 30,
            loop: true,
            smartBackspace: true
        });
    }
}

