import { Application } from "@hotwired/stimulus"
import {end} from "@popperjs/core";

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

