import { Controller } from "@hotwired/stimulus"
import { get }        from '@rails/request.js'
import TomSelect      from "tom-select"


// Connects to data-controller="ts--select"
export default class extends Controller {
  // static values = { url: String }

  connect() {
    let config = {
      plugins: ['no_backspace_delete','remove_button','clear_button'],
      // valueField: 'value',
      maxItems: 5,
      hideSelected: true,
      closeAfterSelect: true,
      // sortField: {
      //   field: 'text',
      //   direction: 'asc'
      // },
      // loadThrottle: 400,
      load: (q, callback) => this.search(q, callback)
    }

    new TomSelect(this.element, config)
  }
  async search(q, callback) {
    const response = await get(this.urlValue, {
      query: { q: q },
      responseKind: 'json'
    })

    if (response.ok) {
      const list = await response.json
      callback(list)
    } else {
      console.log(response)
      callback();
    }
  }

  disconnect() {
    this.element.tomselect.destroy();
  }
}

// console.log("Hey there from MultiSelect controller!");
