// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "./controllers"
import "trix"
import "@rails/actiontext"
import * as bootstrap from "bootstrap"
// import "@fortawesome/fontawesome-free"
// Turbo.session.drive = false
import {  } from 'bootstrap'

document.addEventListener("turbolinks:load", function() {
  let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  // Alerts
  var alertList = document.querySelectorAll('.alert')
  var alerts =  [].slice.call(alertList).map(function (element) {
    return new bootstrap.Alert(element)
  });
});

document.addEventListener("turbo:before-fetch-response", function(event) {
  if (typeof (event.detail.fetchResponse) !== 'undefined') {
    var response = event.detail.fetchResponse.response
    if (response.redirected) {
      event.preventDefault()
      Turbo.visit(response.url)
    }
  }
})

// Tooltips
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))







