import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact"
export default class extends Controller {
  connect() {
    const manager = document.getElementById('job_hiring_manager')
    const hiring_manager = document.querySelector(".contact_hiring_manager")

    document.addEventListener('change', () => {
      if (manager.checked) {
        hiring_manager.classList.add('hidden')
      } else {
        hiring_manager.classList.remove('hidden')
      }
    })
  }
}
