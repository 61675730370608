import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-visibility"
export default class extends Controller {
  connect() {
    super.connect()

    // Do what you want here.
  }

  toggle(event) {
    super.toggle()

    // Do what you want here
  }
}