// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { French } from 'flatpickr/dist/l10n/fr.js'

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  // initialize() {
  //   // sets your language (you can also set some global setting for all time pickers)
  //   this.config = {
  //     locale: French,
  //     altInput: true,
  //     showMonths: 2,
  //     animate: false,
  //     dateFormat: "Y-m-d",
  //     minDate: Time.zone.now + 1.month,
  //     enableTime: false
  //   }
  // }

  connect() {
    this.config = {
      // locale: French,
      altInput: true,
      showMonths: 2,
      animate: false,
      dateFormat: "Y-m-d",
      enableTime: false,
      required: true
    }

    //always call super.connect()
    super.connect();
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  // change(selectedDates, dateStr, instance) {
  //   console.log('the callback returns the selected dates', selectedDates)
  //   console.log('but returns it also as a string', dateStr)
  //   console.log('and the flatpickr instance', instance)
  // }
}